import type { Study } from '@pocketprep/types'

export const environment = (() => {
    if (import.meta.env.VUE_APP_PARSE_URL) {
        if (import.meta.env.VUE_APP_PARSE_URL.includes('localhost')) {
            return 'localhost'
        } else if (import.meta.env.VUE_APP_PARSE_URL.includes('test')) {
            return 'test'
        } else if (import.meta.env.VUE_APP_PARSE_URL.includes('development')) {
            return 'development'
        } else if (import.meta.env.VUE_APP_PARSE_URL.includes('staging')) {
            return 'staging'
        }
    }
    return 'production'
})()

export const formatDate = (date: string | Date) => {
    if (!date) {
        return 'Never'
    }

    let dateObj: Date = new Date()
    if (typeof date === 'string') {
        dateObj = new Date(date)
    } else if (date instanceof Date) {
        dateObj = date
    }
    return ('0' + (dateObj.getMonth() + 1)).slice(-2) + '/'
    + ('0' + dateObj.getDate()).slice(-2) + '/'
    + dateObj.getFullYear()
}

export const nl2br = (str: string) => {
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')
}

export const stripHtmlTags = (value: string) => {
    const div = document.createElement('div')
    div.innerHTML = value
    return div.textContent || ''
}

export const stripCKEditorTags = (content: string) => {
    const createRegExp = (str: TemplateStringsArray) => new RegExp(str.raw[0].replace(/\s/gm, ''), 'gi')

    const insertionRegex = createRegExp`
        <suggestion-start[^>]+name="insertion:[^"]+"><\/suggestion-start>.
        *<suggestion-end[^>]+name="insertion:[^"]+"><\/suggestion-end>`,
        deletionRegex = createRegExp`<suggestion-[^>]+><\/suggestion-[^>]+>`,
        commentRegex = createRegExp`<comment-[^>]+><\/comment-[^>]+>`

    const strippedContent = content
        .replace(insertionRegex, '')
        .replace(deletionRegex, '')
        .replace(commentRegex, '')

    return strippedContent
}

export const bloomTaxonomyLevels: Study.Class.BloomTaxonomyLevel[] = [
    'None',
    'Remember',
    'Understand',
    'Apply',
    'Analyze',
    'Evaluate',
]

export const generateId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    let result = ''
    for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return result
}