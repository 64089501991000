<template>
    <div class="editor-container" :class="{ 'editor-active': editorActive }">
        <div
            v-if="commentCount"
            class="editor-container__comment-indicator"
        >
            <VIcon 
                :icon="commentDots" 
            />
            <span>{{ commentCount }}</span>
        </div>
        <div 
            ref="editor"
            class="editor"
        />
    </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import InlineEditor from '@pocketprep/ckeditor5-build-inline'
import * as R from 'ramda'
import { Component, Vue, Prop, Emit, Watch } from 'vue-facing-decorator'
import { commentsModule } from '@/store/comments/module'
import { usersModule } from '@/store/users/module'
import { isProxy, toRaw } from 'vue'

@Component({
    components: {
        VIcon: FontAwesomeIcon,
    },
})
export default class EditorField extends Vue {
    @Prop({ default: '' }) fieldType?: string
    @Prop({ default: false }) center?: boolean
    @Prop({ default: false }) enableLinks?: boolean
    @Prop({ default: true }) enableComments?: boolean
    @Prop({ default: '' }) modelValue!: string
    @Prop({ default: false }) isReadOnly!: boolean
    @Prop() editorRefresh!: string
    @Prop({ default: false }) autoTrackChanges!: boolean

    editorActive = false

    commentDots = faCommentDots
    editorContent = ''
    editorProxy: ReturnType<(typeof InlineEditor)['create']> | null = null
    commentCount = 0
    LOCK_ID = 'LOCK'

    get users () {
        return usersModule.state.users
    }

    get user () {
        return usersModule.getters.getCurrentUser()
    }

    get isAdmin () {
        return usersModule.getters.getIsAdmin()
    }

    get editor () {
        if (isProxy(this.editorProxy)) {
            return toRaw(this.editorProxy)
        } else {
            return this.editorProxy
        }
    }

    @Watch('editorRefresh')
    onValueChange () {
        if (this.editor && this.editor.isReadOnly !== this.isReadOnly) {
            if (this.isReadOnly) {
                this.editor.enableReadOnlyMode(this.LOCK_ID)
            } else {
                this.editor.disableReadOnlyMode(this.LOCK_ID)
            }
        }
        if (this.editor && this.editorContent !== this.modelValue) {
            this.editor.setData(this.modelValue || '')
            this.editorContent = this.modelValue || ''
        }
    }

    @Emit('update:modelValue')
    valueChange (val: string) {
        return val
    }

    @Emit('editorCreated')
    emitEditor (val: InlineEditor) {
        return val
    }

    updateCommentCount () {
        if (this.editor) {
            this.commentCount = Array.from(this.editor.model.markers.getMarkersGroup('suggestion')).length
                + Array.from(this.editor.model.markers.getMarkersGroup('comment')).length
        }
    }

    async mounted () {
        this.editorContent = this.modelValue
        const self = this // eslint-disable-line @typescript-eslint/no-this-alias

        // NOTE: This adapter Class is required by CKEditor
        // in order to hook into comment + suggestion events. It's dumb
        // and we're mad at CKEditor for it, but its' what
        // we've got to work with for now.
        class CommentsAdapter {
            editor: ReturnType<(typeof InlineEditor)['create']>

            constructor (editor: ReturnType<(typeof InlineEditor)['create']>) {
                this.editor = editor
            }

            init () {
                const usersPlugin = this.editor.plugins.get('Users')
                const commentsRepositoryPlugin = this.editor.plugins.get('CommentsRepository')
                const trackChangesPlugin = this.editor.plugins.get('TrackChanges')

                this.editor.plugins.get('AnnotationsUIs').switchTo('inline')

                // Load the users data.
                for (const user of self.users) {
                    usersPlugin.addUser ({
                        id: user.objectId,
                        name: user.firstName + ' ' + user.lastName,
                    })
                }

                // Set the current user.
                if (self.user) {
                    usersPlugin.defineMe(self.user.objectId)
                }
                
                // Set the adapter to the `Comments#adapter` property.
                commentsRepositoryPlugin.adapter = {
                    getCommentThread: data => commentsModule.actions.fetchCommentsByThread(data.threadId),
                }

                // Set the track changes adapter
                trackChangesPlugin.adapter = {
                    getSuggestion: suggestionId => commentsModule.actions.fetchSuggestionById(suggestionId),
                }
            }
        }

        // configure and create editor instance
        const toolbar = [
            'bold',
            'italic',
            'underline',
            'subscript',
            'superscript',
            ...(this.enableLinks ? [ 'link' ] : []),
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'insertTable',
            'removeFormat',
            ...(this.enableComments ? [ '|', 'comment', 'trackChanges' ] : []),
            ...(this.isAdmin ? [ '|', 'wproofreader' ] : []),
        ]
        const extraPlugins = this.enableComments
            ? [ CommentsAdapter ]
            : []
        const editor = await InlineEditor.create(this.$refs.editor, {
            licenseKey: import.meta.env.VUE_APP_CKEDITOR_LICENSE,
            wproofreader: {
                serviceId: import.meta.env.VUE_APP_WPROOFREADER_KEY,
                srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
                userDictionaryName: 'custom-dictionary',
                disableDictionariesPreferences: !this.isAdmin,
                actionItems: this.isAdmin 
                    ? [ 'addWord', 'ignoreAll', 'ignore', 'settings', 'toggle', 'proofreadDialog' ] 
                    : [ 'ignore' ],
                lang: 'en_US', // en_US for American English
            },
            extraPlugins,
            toolbar,
            initialData: '',
        })
        
        if (this.autoTrackChanges) {
            editor.execute('trackChanges')
        }

        if (this.isReadOnly) {
            editor.enableReadOnlyMode(this.LOCK_ID)
        }

        editor.ui.focusTracker.on('change:isFocused', (evt: unknown, name: unknown, isFocused: boolean) => {
            this.editorActive = isFocused
        })

        // listen for content changes and pass to parent component
        editor.data.model.document.on('change', async () => {
            if (!R.equals(editor.getData(), this.editorContent)) {
                this.editorContent = editor.getData()
                this.valueChange(editor.getData())
            }
            self.updateCommentCount()
        })

        // Emit editor
        editor.setData(this.modelValue || '')
        this.editorProxy = editor
        this.emitEditor(editor)
    }
}
</script>

<style lang="scss" scoped>

.editor-container {
    position: relative;
    display: flex;


    .editor {
        position: relative;
        width: 100%;
        border: 1px solid rgba($pewter, 0.85);
        border-radius: 3px;
    }

    &.editor-active {
        z-index: 2;
    }

    &__comment-indicator {
        position: absolute;
        z-index: 1;
        right: 0;
        top: -20px;
        user-select: none;

        @include breakpoint(900px) {
            left: auto;
            right: 0;
        }

        svg {
            transform: scale(-1, 1);
        }

        span {
            padding-left: 5px;
        }
    }
}
</style>

<style lang="scss">
.ck.ck-balloon-panel.ck-toolbar-container {
    z-index: 2;
}

.ck-toolbar {
    min-width: 480px;
    @include breakpoint(900px) {
        & > :nth-last-child(1),
        & > :nth-last-child(2),
        & > :nth-last-child(3) {
            display: none !important;
        }
    }
}
</style>