<template>
    <Teleport to="body">
        <ModalContainer
            :trap-focus="false"
            @close="close"
        >
            <div class="edit-scenario-modal">
                <div class="edit-scenario-modal__header">
                    <div class="edit-scenario-modal__title">
                        Edit Scenario
                    </div>
                    <div class="edit-scenario-modal__key">
                        {{ scenarioDraft.key }}
                    </div>
                </div>
                <div class="edit-scenario-modal__content">
                    <div class="edit-scenario-modal__banner-section">
                        <InfoMessage class="edit-scenario-modal__banner">
                            Changes save for all questions in the scenario
                        </InfoMessage>
                    </div>
                    <div class="edit-scenario-modal__unfolding-section">
                        <ToggleSwitch
                            v-model="isUnfolding"
                            size="large"
                        />
                        <div class="edit-scenario-modal__unfolding-toggle-label">
                            <div class="edit-scenario-modal__unfolding-toggle-text">
                                Unfolding Scenario
                            </div>
                            <div class="edit-scenario-modal__unfolding-toggle-subtext">
                                Supplemental information changes across questions
                            </div>
                        </div>
                    </div>
                    <div v-if="isEditingSupplementalInfo" class="edit-scenario-modal__supplemental-info-edit-section">
                        <PocketSelect
                            class="edit-scenario-modal__supplemental-info-label-select"
                            label="Supplemental Information Label"
                            v-model="selectedSupplementalInfoLabel"
                            :data="supplementalInfoLabelOptions"
                        />
                        <template v-if="!isUnfolding">
                            <div class="edit-scenario-modal__supplemental-info-field-label">
                                {{ selectedSupplementalInfoLabel ? selectedSupplementalInfoLabel.label : 'Passage' }}
                            </div>
                            <EditorField
                                class="edit-scenario-modal__supplemental-info-field"
                                v-model="supplementalInfo"
                                :editor-refresh="Date.now()"
                                :auto-track-changes="autoTrackChanges"
                                @editorCreated="storeEditor"
                            />
                        </template>
                    </div>
                    <div
                        v-if="!isEditingSupplementalInfo || isUnfolding"
                        class="edit-scenario-modal__supplemental-info-section"
                    >
                        <div class="edit-scenario-modal__supplemental-info-label">
                            {{ selectedSupplementalInfoLabel ? selectedSupplementalInfoLabel.label : 'Passage' }}
                        </div>
                        <div v-if="!isUnfolding" class="edit-scenario-modal__supplemental-info">
                            {{ stripHtmlTags(scenarioDraft.sharedPassage || '--') }}
                        </div>
                        <div v-else class="edit-scenario-modal__supplemental-info">
                            Scenarios will vary; {{
                                scenarioPassageVariationCount
                            }} variation{{ scenarioPassageVariationCount === 1 ? '' : 's' }} across {{
                                orderedQuestionDrafts.length
                            }} question{{ orderedQuestionDrafts.length === 1 ? '' : 's' }}
                        </div>
                        <Icon
                            v-if="!isEditingSupplementalInfo"
                            class="edit-scenario-modal__edit-supplemental-info-btn"
                            :class="{
                                'edit-scenario-modal__edit-supplemental-info-btn--unfolding': isUnfolding,
                            }"
                            type="edit"
                            @click="editSupplementalInfoClicked"
                        />
                    </div>
                    <div class="edit-scenario-modal__order-table">
                        <div class="edit-scenario-modal__order-table-column-labels">
                            <div class="edit-scenario-modal__order-table-order-column">
                                Order
                            </div>
                            <div class="edit-scenario-modal__order-table-serial-column">
                                Serial/ID
                            </div>
                            <div class="edit-scenario-modal__order-table-subject-column">
                                Subject
                            </div>
                            <div class="edit-scenario-modal__order-table-type-column">
                                Type
                            </div>
                            <div class="edit-scenario-modal__order-table-prompt-column">
                                Question Prompt
                            </div>
                        </div>
                        <div class="edit-scenario-modal__order-table-rows">
                            <div
                                class="edit-scenario-modal__order-table-row"
                                v-for="(questionDraft, index) in orderedQuestionDrafts"
                                :key="'serial' in questionDraft ? questionDraft.serial : questionDraft.tempId"
                            >
                                <div class="edit-scenario-modal__order-table-order-column">
                                    <PocketSelect
                                        class="edit-scenario-modal__order-select"
                                        :modelValue="{
                                            value: index + 1,
                                            label: String(index + 1).padStart(2, '0')
                                        }"
                                        @update:modelValue="updateOrder({
                                            oldOrder: index + 1,
                                            newOrder: $event.value
                                        })"
                                        :data="orderDropdownOptions"
                                    />

                                </div>
                                <div class="edit-scenario-modal__order-table-serial-column">
                                    {{
                                        'serial' in questionDraft
                                            ? questionDraft.serial
                                            : '--'
                                    }}
                                </div>
                                <div class="edit-scenario-modal__order-table-subject-column">
                                    {{
                                        'knowledgeAreaDraft' in questionDraft && questionDraft.knowledgeAreaDraft
                                            ? subjectIdNameLib[questionDraft.knowledgeAreaDraft.objectId]
                                            : 'knowledgeAreaDraftId' in questionDraft
                                                ? subjectIdNameLib[questionDraft.knowledgeAreaDraftId]
                                                : '--'
                                    }}
                                </div>
                                <div class="edit-scenario-modal__order-table-type-column">
                                    {{ shortenQuestionType(questionDraft.type) }}
                                </div>
                                <div class="edit-scenario-modal__order-table-prompt-column">
                                    {{ stripHtmlTags(questionDraft.prompt || '--') }}
                                </div>
                                <Icon
                                    class="edit-scenario-modal__order-table-row-remove"
                                    type="close"
                                    @click="removeQuestionFromScenario(index)"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="edit-scenario-modal__add-question-btns">
                        <PocketButton
                            type="secondary"
                            @click="addNewQuestionDraft"
                        >
                            Add New Question Draft
                        </PocketButton>
                        <PocketButton
                            type="secondary"
                        >
                            Add Existing Question Draft
                        </PocketButton>
                    </div>
                </div>
                <div class="edit-scenario-modal__footer">
                    <PocketButton
                        type="secondary"
                        @click="cancel"
                    >
                        Cancel
                    </PocketButton>
                    <PocketButton
                        :disabled="isLoading"
                        :is-loading="isLoading"
                        @click="save"
                    >
                        Save Scenario
                    </PocketButton>
                </div>
            </div>
        </ModalContainer>
    </Teleport>
</template>

<script lang="ts">
import { CMS } from '@pocketprep/types'
import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'
import InlineEditor from '@pocketprep/ckeditor5-build-inline'
import UIKit from '@pocketprep/ui-kit'
import type { IParseQuestionDraft } from '@/store/questionDrafts/types'
import type { TQuestionType } from '@/store/types'
import { EditorField } from '@/components/Forms'
import InfoMessage from '@/components/InfoMessage.vue'
import { stripHtmlTags } from '@/utils'
import questionScenarioDraftsModule from '@/store/questionScenarioDrafts/module'
import { generateId } from '@/utils'
import type { INewScenarioQuestionDraft } from '@/store/questionScenarioDrafts/types'
import questionDraftsModule from '@/store/questionDrafts/module'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        PocketButton: UIKit.Button,
        ToggleSwitch: UIKit.ToggleSwitch,
        PocketSelect: UIKit.Select,
        Icon: UIKit.Icon,
        EditorField,
        InfoMessage,
    },
})
class EditScenarioModal extends Vue {
    @Prop() scenarioDraft!: CMS.Class.QuestionScenarioDraftJSON
    @Prop({ default: '' }) supplementalInfoLabel!: string
    @Prop({ default: [] }) jobQuestionDrafts!: (IParseQuestionDraft & { serial: string })[]
    @Prop({ default: [] }) subjects!: CMS.Class.KnowledgeAreaDraftJSON[]
    @Prop() jobId!: string | null
    @Prop() knowledgeAreaDraftId!: string | null
    @Prop() examDraftId!: string | null
    @Prop() mockExamDraft!: CMS.Class.MockExamDraftJSON | null
    @Prop({ default: false }) autoTrackChanges!: boolean

    isLoading = false
    isUnfolding = false
    isEditingSupplementalInfo = false
    selectedSupplementalInfoLabel: { value: string; label: string } | null = null
    supplementalInfo = ''
    orderedQuestionDrafts: ((IParseQuestionDraft & { serial: string }) | INewScenarioQuestionDraft)[] = []
    editors: ReturnType<(typeof InlineEditor)['create']>[] = [] // Need the editor instances to save comments later
    removedQuestionDrafts: EditScenarioModal['orderedQuestionDrafts'] = []

    get jobQuestionDraftsBySerial () {
        return Object.fromEntries(this.jobQuestionDrafts.map(qDraft => [ qDraft.serial, qDraft ]))
    }

    get scenarioPassageVariationCount () {
        const allPassagesSet = new Set(this.orderedQuestionDrafts.map(qDraft => qDraft.passage))
        return allPassagesSet.size
    }

    get subjectIdNameLib () {
        return Object.fromEntries(this.subjects.map(subject => [ subject.objectId, subject.name ]))
    }

    get supplementalInfoLabelOptions () {
        return [{
            value: 'Case Study',
            label: 'Case Study',
        }, {
            value: 'Passage',
            label: 'Passage',
        }, {
            value: 'Patient Data',
            label: 'Patient Data',
        }, {
            value: 'Scenario',
            label: 'Scenario',
        }]
    }

    get orderDropdownOptions () {
        return this.orderedQuestionDrafts.map((qDraft, index) => ({
            value: index + 1,
            label: String(index + 1).padStart(2, '0'),
        }))
    }

    async mounted () {
        this.selectedSupplementalInfoLabel = this.supplementalInfoLabelOptions.find(option =>
            option.label === this.supplementalInfoLabel
        ) || null

        this.isUnfolding = !!this.scenarioDraft.isUnfolding
        this.supplementalInfo = this.scenarioDraft.sharedPassage || ''
        this.orderedQuestionDrafts = this.scenarioDraft.questionDrafts.map(qDraft =>
            this.jobQuestionDraftsBySerial[qDraft.serial]
        )
    }

    stripHtmlTags = stripHtmlTags

    shortenQuestionType (type: TQuestionType) {
        if (type === 'Multiple Choice') {
            return 'MC'
        } else if (type === 'Multiple Correct Response') {
            return 'MCR'
        } else if (type === 'True/False') {
            return 'TF'
        } else if (type === 'Matrix Checkbox') {
            return 'mCB'
        } else if (type === 'Matrix Radio Button') {
            return 'mRB'
        }
    }

    storeEditor (editor: ReturnType<(typeof InlineEditor)['create']>) {
        this.editors.push(editor)
    }

    editSupplementalInfoClicked () {
        this.isEditingSupplementalInfo = true
    }

    addNewQuestionDraft () {
        const commentThreads = this.editors.map(editor => {
            const commentsRepo = editor.plugins.get('CommentsRepository')

            return commentsRepo.getCommentThreads({
                skipNotAttached: true,
                skipEmpty: true,
                toJSON: true,
            })
        }).flat()

        const suggestions = this.editors.map(editor => {
            const trackChangesRepo = editor.plugins.get('TrackChanges')
            return trackChangesRepo.getSuggestions()
        }).flat()

        if (this.knowledgeAreaDraftId && this.examDraftId) {
            const tempQuestionDraft: INewScenarioQuestionDraft = {
                tempId: generateId(),
                knowledgeAreaDraftId: this.knowledgeAreaDraftId,
                passage: this.supplementalInfo,
                prompt: '',
                explanation: 'Correct answer: ',
                type: 'Multiple Choice',
                draftStatus: this.jobId ? 'active' : 'inactive',
                examDraftId: this.examDraftId,
                hasComments: !!(commentThreads.length),
                hasSuggestions: !!(suggestions.length),
                isMockQuestion: !!this.mockExamDraft,
            }
            this.orderedQuestionDrafts.push(tempQuestionDraft)
        }
    }

    updateOrder ({ oldOrder, newOrder }: { oldOrder: number; newOrder: number }) {
        const list = this.orderedQuestionDrafts
        const elementToMove = list.splice(oldOrder - 1, 1)
        list.splice(newOrder - 1, 0, elementToMove[0])
    }

    removeQuestionFromScenario (index: number) {
        const removedQDraft = this.orderedQuestionDrafts.splice(index, 1)
        this.removedQuestionDrafts.push(removedQDraft[0])
    }

    cancel () {
        this.close()
    }

    async save () {
        const examDraftId = this.examDraftId
        const knowledgeAreaDraftId = this.knowledgeAreaDraftId
        if (!examDraftId || !knowledgeAreaDraftId) {
            throw new Error('Cannot save Scenario without examDraftId and knowledgeAreaDraftId')
        }

        this.isLoading = true
        // Get most recent state of CKEditor comments/suggestions in case they changed since adding question to the list
        const commentThreads = this.editors.map(editor => {
            const commentsRepo = editor.plugins.get('CommentsRepository')

            return commentsRepo.getCommentThreads({
                skipNotAttached: true,
                skipEmpty: true,
                toJSON: true,
            })
        }).flat()

        const suggestions = this.editors.map(editor => {
            const trackChangesRepo = editor.plugins.get('TrackChanges')
            return trackChangesRepo.getSuggestions()
        }).flat()

        const scenarioQDraftPromises: Promise<CMS.Class.QuestionDraftJSON>[] = []

        // Start saving the scenario's question drafts while maintaining the list order
        this.orderedQuestionDrafts.forEach(qDraft => {
            // Update existing question drafts and create new ones if necessary
            if ('objectId' in qDraft) {
                const questionDraftToUpdate = questionDraftsModule.actions.updateQuestionDraft({
                    questionId: qDraft.objectId,
                    params: {
                        draftStatus: qDraft.draftStatus,
                        hasComments: !!(commentThreads.length),
                        hasSuggestions: !!(suggestions.length),
                        isArchived: qDraft.isArchived,
                        isClassic: qDraft.isClassic,
                        isFlagged: qDraft.isFlagged,
                        isSpecial: qDraft.isSpecial,
                        type: qDraft.type,
                        examDraftId: examDraftId,
                        passage: this.isUnfolding ? qDraft.passage : this.supplementalInfo,
                        questionScenarioDraftId: this.scenarioDraft.objectId,
                    },
                })
                scenarioQDraftPromises.push(questionDraftToUpdate)
            } else {
                const questionDraftToCreate = questionDraftsModule.actions.createQuestionDraft({
                    jobId: this.jobId || undefined,
                    draftStatus: qDraft.draftStatus,
                    hasComments: !!(commentThreads.length),
                    hasSuggestions: !!(suggestions.length),
                    isArchived: false,
                    isClassic: false,
                    isFlagged: false,
                    isSpecial: false,
                    type: qDraft.type,
                    examDraftId: examDraftId,
                    knowledgeAreaDraftId: knowledgeAreaDraftId,
                    passage: this.supplementalInfo,
                    prompt: qDraft.prompt,
                    explanation: qDraft.explanation,
                    questionScenarioDraftId: this.scenarioDraft.objectId,
                    isMockQuestion: qDraft.isMockQuestion,
                })
                scenarioQDraftPromises.push(questionDraftToCreate)
            }
        })
        const scenarioQDrafts = await Promise.all(scenarioQDraftPromises) as (
            CMS.Class.QuestionDraftJSON & { serial: string }
        )[]

        // Unset scenario pointer from removed question drafts
        const removedQDraftsToUpdate = this.removedQuestionDrafts.map(removedQDraft => {
            if ('objectId' in removedQDraft) {
                return questionDraftsModule.actions.updateScenarioDraftOnQuestionDraft({
                    questionDraftId: removedQDraft.objectId,
                    newScenarioDraftId: undefined,
                })
            }

            return undefined
        })
        await Promise.all(removedQDraftsToUpdate)

        await questionScenarioDraftsModule.actions.updateQuestionScenarioDraft({
            objectId: this.scenarioDraft.objectId,
            sharedPassage: this.supplementalInfo,
            questionDrafts: scenarioQDrafts.map(qDraft => ({
                serial: qDraft.serial,
            })),
            isUnfolding: this.isUnfolding,
        })

        // Update mock exam draft with new serials (scenario questions should always be in the same question bank)
        const mockExamDraft = this.mockExamDraft
        const mockExamDraftSerialSet = new Set(mockExamDraft?.questionSerials)
        if (mockExamDraft) {
            scenarioQDrafts.forEach(qDraft => {
                if (!mockExamDraftSerialSet.has(qDraft.serial)) {
                    mockExamDraft.questionSerials.push(qDraft.serial)
                }
            })
            mockExamDraftsModule.actions.updateMockExamDraft(mockExamDraft)
        }

        this.submit({
            sharedPassage: this.supplementalInfo,
            scenarioCommentThreads: commentThreads,
            scenarioSuggestions: suggestions,
        })
    }

    @Emit('close')
    close () {
        return true
    }

    @Emit('submit')
    submit (params: {
        sharedPassage: string
        scenarioCommentThreads: unknown[]
        scenarioSuggestions: unknown[]
    }) {
        return params
    }
}
export default toNative(EditScenarioModal)
</script>

<style lang="scss" scoped>
.edit-scenario-modal {
    width: 982px;
    height: 600px;
    padding-bottom: 56px;
    position: relative;
    background-color: $barely-background;
    box-shadow: 0px 1px 40px 0px rgba($pewter, 0.5);

    &__header {
        display: flex;
        padding: 18px 30px 14px;
        border-bottom: 1px solid $fog;
        background-color: $white;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-right: 12px;
    }

    &__key {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $ash;
    }

    &__content {
        height: 490px;
        overflow: auto;
    }

    &__banner-section {
        margin: 16px 30px 30px 30px;
    }

    &__unfolding-section {
        display: flex;
        margin-left: 30px;
        margin-bottom: 20px;
    }

    &__unfolding-toggle-label {
        margin-left: 12px;
    }

    &__unfolding-toggle-text {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        margin-top: 3px;
        margin-bottom: 3px;
    }

    &__unfolding-toggle-subtext {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: $ash;
    }

    &__supplemental-info-section {
        display: flex;
        padding-left: 62px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 30px;
        position: relative;
    }

    &__supplemental-info-label {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        margin-right: 12px;
    }

    &__supplemental-info {
        height: 40px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        padding-right: 36px;
    }

    &__edit-supplemental-info-btn {
        position: absolute;
        right: -2px;
        top: -1px;
        color: $brand-blue;
        cursor: pointer;

        &:hover {
            color: $brand-black;
        }

        &--unfolding {
            position: static;
            margin-left: 12px;
        }
    }

    &__supplemental-info-edit-section {
        margin-left: 30px;
        margin-right: 30px;
        padding-left: 62px;
    }

    &__supplemental-info-label-select {
        margin-bottom: 18px;
        width: 396px;
    }

    &__supplemental-info-field-label {
        padding-left: 12px;
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: $pickled-bluewood;
    }

    &__supplemental-info-field {
        margin-bottom: 30px;
    }

    &__order-table {
        position: relative;
        margin-bottom: 10px;
    }

    &__order-table-column-labels {
        display: flex;
        align-items: center;
        background-color: $gray-background;
        height: 30px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        border-top: 1px solid rgba($pewter, 0.3);
        border-bottom: 1px solid rgba($pewter, 0.3);
    }

    &__order-table-order-column {
        width: 98px;
        padding-left: 30px;
    }

    &__order-select {
        width: 62px;

        :deep(.uikit-select__value) {
            height: 28px;
            padding: 4px 28px 4px 12px;
        }

        :deep(.uikit-select__arrow) {
            top: 11px;
            right: 10px;
        }

        :deep(.uikit-select__list) {
            top: 28px !important;
            padding: 0;
        }
    }

    &__order-table-serial-column {
        width: 122px;
        padding-left: 15px;
    }

    &__order-table-subject-column {
        width: 230px;
        padding-left: 15px;
    }

    &__order-table-type-column {
        width: 60px;
        padding-left: 15px;
    }

    &__order-table-prompt-column {
        width: 421px;
        padding-left: 15px;
        padding-right: 15px;
        height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        direction: rtl;
        text-align: left;

        // \200E character (&lrm;) allows left-to-right punctuation with left-side ellipsis
        &::after {
            content: '\200E';
        }
    }

    &__order-table-row {
        display: flex;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid $fog;
        background-color: $white;
    }

    &__order-table-row-remove {
        color: $brand-blue;
        cursor: pointer;

        &:hover {
            color: $brand-black;
        }
    }

    &__add-question-btns {
        margin-left: 30px;
        margin-bottom: 16px;
        position: relative;
        left: -18px;
        display: flex;
        gap: 10px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 56px;
        border-top: 1px solid $fog;
        background-color: $white;
        padding: 9px 30px 8px;
        gap: 8px;
    }
}
</style>
